import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
					<path d="M5500 9379 c-32 -6 -30 -7 20 -9 52 -3 53 -4 13 -7 -24 -2 -43 -8
-43 -14 0 -5 -11 -7 -25 -3 -15 4 -25 2 -25 -5 0 -6 13 -11 28 -11 42 0 52
-17 52 -87 0 -47 4 -64 17 -71 11 -7 14 -15 9 -28 -9 -21 -8 -386 0 -419 6
-24 -30 -70 -63 -80 -28 -8 -30 -35 -3 -35 11 0 20 7 20 15 0 8 5 15 10 15 6
0 10 -7 10 -15 0 -12 20 -13 127 -10 126 4 142 9 143 43 0 20 -21 14 -27 -8
-4 -13 -14 -20 -31 -20 -24 0 -24 1 -8 13 17 12 16 14 -8 25 -33 15 -42 39
-51 135 -7 73 -6 79 15 97 13 11 26 20 29 21 3 0 38 1 76 3 55 1 82 8 127 31
32 17 58 35 58 42 0 7 9 17 20 23 11 6 20 17 20 25 0 8 5 15 10 15 6 0 10 13
10 29 0 16 4 31 9 33 4 2 12 14 16 27 5 15 4 21 -3 17 -15 -9 -24 11 -17 35 6
17 9 19 17 8 19 -28 -4 36 -28 78 -39 67 -94 102 -108 68 -6 -17 -36 -21 -36
-5 0 6 8 10 17 10 14 0 14 3 5 13 -14 13 -335 18 -402 6z m160 -23 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m37 -2 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m128 -24 c-3 -6 5 -14 18 -19
31 -12 62 -56 71 -101 8 -43 28 -90 38 -90 5 0 8 -7 8 -15 0 -8 -4 -15 -9 -15
-5 0 -11 7 -15 15 -10 26 -26 16 -26 -15 0 -49 -29 -89 -80 -112 -39 -17 -53
-19 -78 -6 -16 8 -25 9 -33 1 -8 -8 -18 -5 -35 10 -20 17 -24 31 -25 76 0 41
-3 50 -9 36 -8 -19 -9 -19 -9 2 -1 12 4 23 9 25 7 2 6 15 -2 37 -10 27 -10 35
0 38 14 5 17 63 3 63 -5 0 -11 7 -14 14 -4 10 -1 13 8 10 7 -3 16 3 20 15 7
23 25 29 100 34 28 2 54 4 58 5 5 1 5 -3 2 -8z m143 -26 c-10 -10 -28 6 -28
24 0 15 1 15 17 -1 9 -9 14 -19 11 -23z m-15 -61 c6 -34 3 -53 -7 -42 -9 10
-16 79 -8 79 5 0 12 -17 15 -37z m67 -7 c0 -8 -4 -18 -10 -21 -5 -3 -10 3 -10
14 0 12 5 21 10 21 6 0 10 -6 10 -14z m-440 -140 c0 -14 -4 -27 -9 -30 -12 -8
-22 20 -15 39 9 23 24 18 24 -9z m37 -43 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18
2 -10 2 -26 0 -35z m-10 -78 c0 -8 -4 -12 -9 -9 -4 3 -8 9 -8 15 0 5 4 9 8 9
5 0 9 -7 9 -15z m-22 -85 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10
16 10 6 0 7 -4 4 -10z m2 -137 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2
-26 0 -35z m93 -108 c0 -8 -7 -15 -16 -15 -14 0 -14 3 -4 15 7 8 14 15 16 15
2 0 4 -7 4 -15z"/>
					<path d="M6364 9374 c4 -10 -3 -14 -22 -15 -27 -2 -27 -2 -2 -6 48 -9 53 -21
47 -108 -4 -44 -11 -120 -18 -170 -6 -49 -11 -102 -10 -117 0 -16 -4 -28 -9
-28 -6 0 -10 -29 -10 -65 0 -57 2 -64 18 -58 16 7 16 6 0 -11 -32 -32 -23 -69
33 -125 81 -82 87 -84 218 -88 108 -3 119 -2 167 22 65 33 115 101 121 165 3
25 9 56 14 70 5 14 11 52 14 85 10 110 25 220 35 248 8 25 7 30 -10 34 -27 7
-25 23 2 23 12 0 30 8 41 17 10 10 26 18 35 18 9 0 18 8 20 18 3 13 -3 17 -27
17 -22 0 -29 -4 -25 -14 4 -11 0 -13 -15 -9 -23 6 -29 23 -8 23 8 0 7 4 -3 10
-8 6 -35 10 -60 9 -25 -1 -58 4 -73 10 -37 14 -37 14 -37 -9 0 -12 9 -22 24
-26 15 -3 29 -17 35 -35 5 -16 20 -33 33 -39 22 -8 11 -15 -19 -11 -7 1 -13
-5 -13 -14 0 -8 5 -15 10 -15 6 0 7 -6 1 -17 -16 -32 -31 -117 -30 -173 0 -30
-4 -71 -10 -90 -6 -19 -11 -54 -11 -77 0 -23 -5 -54 -12 -70 -6 -15 -12 -30
-12 -33 -3 -13 -54 -57 -79 -68 -15 -7 -52 -12 -80 -12 -109 0 -177 81 -177
210 0 34 4 70 10 80 10 19 28 134 35 225 3 36 2 42 -4 20 l-7 -30 -5 33 c-5
26 -2 32 13 32 18 0 32 34 26 64 -2 9 7 22 21 29 13 6 29 21 35 32 6 11 12 22
14 25 2 2 8 -2 13 -10 9 -13 10 -13 7 0 -4 11 -23 14 -88 13 -51 -1 -78 2 -69
8 10 6 8 9 -11 10 -14 1 -40 2 -58 4 -25 2 -32 -1 -28 -11z m51 -84 c3 -5 1
-10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m462 -182 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m50 -50 c-2 -13 -4 -3 -4 22
0 25 2 35 4 23 2 -13 2 -33 0 -45z m-57 -22 c0 -14 -4 -28 -10 -31 -6 -4 -10
7 -10 24 0 17 5 31 10 31 6 0 10 -11 10 -24z m-471 -13 c-1 -10 -5 -29 -9 -43
-7 -21 -8 -18 -7 18 0 23 5 42 9 42 4 0 8 -8 7 -17z m26 -195 c-2 -15 1 -28 6
-28 6 0 7 -5 3 -11 -11 -18 -24 12 -24 56 0 27 3 36 9 26 5 -8 8 -27 6 -43z
m445 12 c0 -22 -4 -40 -9 -40 -5 0 -11 -6 -14 -12 -2 -7 -3 -4 -1 7 2 11 4 35
4 53 0 17 5 32 10 32 6 0 10 -18 10 -40z m-492 -111 c7 -12 11 -23 8 -26 -7
-7 -36 23 -36 36 0 18 14 13 28 -10z m440 -60 c-2 -6 -8 -10 -13 -10 -5 0 -11
4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m-333 -28 c-3 -6 -11 -11 -16 -11
-5 0 -4 6 3 14 14 16 24 13 13 -3z m292 -7 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m-223 -10 c27 -10 18 -21 -14 -16 -16 2 -30 8 -30 13 0
10 21 12 44 3z"/>
					<path d="M4745 9260 c-87 -25 -141 -56 -192 -107 -58 -60 -74 -94 -79 -165 -2
-35 -10 -65 -19 -74 -19 -20 -19 -38 1 -59 12 -12 15 -28 11 -61 -4 -41 6 -70
15 -42 5 15 28 1 28 -18 0 -8 -7 -14 -15 -14 -8 0 -15 -9 -15 -19 0 -10 7 -21
15 -25 8 -3 15 -15 15 -26 0 -11 5 -20 10 -20 6 0 10 -6 10 -12 1 -7 9 -23 20
-36 15 -18 23 -21 38 -13 11 6 24 6 33 0 10 -6 6 -9 -16 -9 l-30 0 26 -25 c26
-27 104 -59 169 -70 39 -7 77 7 123 44 9 8 20 11 24 8 3 -4 1 -7 -5 -7 -7 0
-12 -7 -12 -15 0 -8 7 -15 15 -15 8 0 15 4 15 8 0 4 17 14 37 21 66 24 219
197 184 209 -13 4 -15 42 -2 42 5 0 12 -6 14 -12 7 -16 9 22 10 138 0 76 -5
104 -27 163 -26 67 -46 91 -46 55 0 -8 -5 -12 -10 -9 -6 3 -7 17 -4 30 4 16
-2 34 -17 55 -21 28 -27 30 -64 25 -38 -6 -39 -5 -20 10 17 14 18 17 5 25 -8
5 -24 10 -35 10 -11 0 -30 6 -41 14 -26 19 -95 17 -169 -4z m185 -20 c0 -5
-12 -10 -27 -9 -25 0 -26 1 -8 9 27 12 35 12 35 0z m30 -40 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-313 -20 c6 -21 37 -27
48 -10 3 6 19 10 35 10 16 0 32 5 35 10 3 6 21 9 38 9 l32 -2 -35 -6 c-34 -6
-33 -7 19 -14 33 -4 63 -14 76 -27 12 -11 30 -20 41 -21 12 0 14 -3 6 -6 -10
-4 -7 -12 11 -32 29 -30 52 -86 78 -191 6 -25 15 -59 20 -77 7 -22 7 -39 0
-52 -6 -11 -11 -40 -11 -65 0 -25 -5 -48 -10 -51 -6 -4 -8 -10 -6 -14 8 -12
-44 -56 -95 -80 -46 -22 -129 -29 -129 -11 0 6 -8 10 -18 10 -10 0 -37 16 -59
35 -22 19 -43 32 -46 29 -3 -3 -8 -1 -12 5 -3 6 -1 11 6 11 9 0 8 5 -3 18 -8
9 -23 37 -33 62 -10 25 -26 51 -34 58 -14 11 -14 14 -1 22 11 7 12 12 3 18 -7
4 -12 49 -14 123 -3 100 -1 120 15 145 11 16 27 34 35 41 12 8 13 15 5 23 -13
13 -44 3 -44 -16 0 -19 -16 -18 -24 2 -7 19 -8 17 22 33 12 7 20 16 17 22 -4
5 1 9 10 9 9 0 19 -9 22 -20z m400 -52 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2
-7 2 -19 0 -25z m-27 -18 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4
10 6 0 11 -4 11 -10z m-463 -42 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12
1 -19z m-31 -25 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z
m595 -58 c-9 -30 -9 -30 -8 7 1 20 5 34 9 30 4 -4 3 -21 -1 -37z m-71 -41 c0
-8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m77 -51
c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-4 -104 c-4 -15 -8
-17 -14 -8 -6 11 4 28 18 29 0 0 -1 -9 -4 -21z m-33 -80 c0 -6 -4 -7 -10 -4
-5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m30 -35 c0 -8 -4 -12
-10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-480 -50 c0 -8 -4
-12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-65 -13 c3 -5
1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m474 -8 c-39 -50 -63 -60 -29
-13 12 17 26 30 32 30 6 0 5 -7 -3 -17z m-369 -9 c0 -8 -4 -12 -10 -9 -5 3
-10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m40 -34 c0 -5 -5 -10 -11 -10
-5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-45 -30 c3 -5 -3 -10 -15
-10 -12 0 -18 5 -15 10 3 6 10 10 15 10 5 0 12 -4 15 -10z m115 -20 c13 -8 13
-10 -2 -10 -9 0 -20 5 -23 10 -8 13 5 13 25 0z"/>
					<path d="M7380 9181 c0 -5 8 -12 18 -15 32 -10 48 -52 34 -88 -6 -17 -9 -35
-5 -41 3 -6 1 -7 -5 -3 -8 5 -12 -3 -12 -22 0 -17 -6 -32 -15 -36 -8 -3 -15
-18 -15 -34 0 -15 -7 -37 -15 -48 -8 -10 -15 -27 -15 -38 0 -26 -49 -186 -66
-218 -9 -16 -12 -38 -8 -53 5 -20 3 -24 -10 -19 -12 5 -15 2 -10 -10 4 -12 0
-16 -15 -16 -24 0 -25 -3 -9 -30 17 -27 -1 -40 -19 -12 -15 22 -63 31 -63 12
0 -5 7 -10 16 -10 10 0 14 -6 10 -15 -4 -11 2 -15 22 -15 15 0 36 -7 46 -15
11 -8 30 -15 42 -15 13 0 26 -5 30 -11 4 -7 23 -9 50 -5 35 4 42 3 38 -9 -4
-8 -2 -15 4 -15 6 0 17 -3 25 -6 11 -4 15 1 13 20 -1 19 -6 26 -22 26 -31 0
-49 27 -45 66 1 19 -2 34 -8 34 -16 0 -14 16 4 34 8 8 15 26 15 40 0 16 6 26
15 26 9 0 15 9 15 21 0 19 4 20 38 16 20 -3 60 -11 87 -17 86 -19 122 -22 129
-11 4 6 19 11 34 11 25 0 132 57 132 71 0 3 10 19 22 34 12 16 30 53 40 82
l18 54 -27 57 c-16 31 -34 56 -40 54 -7 -1 -13 5 -13 12 0 17 -60 66 -81 66
-8 0 -23 7 -33 15 -10 8 -23 12 -28 9 -5 -3 -30 -3 -56 1 -26 4 -41 10 -34 12
18 7 4 23 -21 23 -12 0 -30 7 -40 14 -13 10 -27 12 -47 6 -20 -6 -30 -4 -33 4
-6 15 -87 21 -87 7z m184 -86 c-19 -30 -14 -41 13 -27 10 5 37 14 61 19 32 8
42 7 42 -3 0 -7 -6 -15 -12 -17 -7 -3 -4 -6 6 -6 24 -1 86 -69 86 -95 0 -10 7
-24 16 -31 14 -10 14 -15 5 -34 -7 -12 -12 -34 -11 -49 3 -47 1 -59 -9 -44 -6
10 -11 5 -20 -16 -23 -56 -107 -111 -146 -96 -9 4 -15 0 -15 -10 0 -17 -17
-20 -44 -10 -21 8 -20 22 2 26 12 3 5 10 -25 26 -43 23 -73 60 -56 71 13 8 43
87 43 110 0 11 7 26 15 35 9 8 15 18 14 21 0 3 5 20 12 36 7 18 8 34 3 37 -26
19 -9 82 22 82 12 0 11 -5 -2 -25z m-64 -45 c0 -5 -2 -10 -4 -10 -3 0 -8 5
-11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m288 -8 c2 -7 -5 -12 -17 -12 -14 0
-19 5 -14 15 6 17 25 15 31 -3z m92 -102 c0 -11 -2 -20 -4 -20 -2 0 -6 9 -9
20 -3 11 -1 20 4 20 5 0 9 -9 9 -20z m-70 -6 c0 -8 -5 -12 -10 -9 -6 4 -8 11
-5 16 9 14 15 11 15 -7z m-405 -72 c-2 -9 -4 -23 -4 -30 -2 -30 -21 2 -21 35
0 29 2 33 14 24 8 -7 13 -20 11 -29z m31 -59 c-10 -10 -19 5 -10 18 6 11 8 11
12 0 2 -7 1 -15 -2 -18z m420 -4 c3 -6 2 -14 -3 -18 -6 -3 -13 -13 -16 -21 -3
-8 -6 0 -6 18 -1 32 11 42 25 21z m-41 -69 c3 -5 -3 -10 -14 -10 -12 0 -21 5
-21 10 0 6 6 10 14 10 8 0 18 -4 21 -10z m-55 -36 c0 -2 -9 -4 -21 -4 -11 0
-18 4 -14 10 5 8 35 3 35 -6z m-390 -24 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10
-3 6 -1 10 4 10 6 0 11 -4 11 -10z"/>
					<path d="M7648 9123 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
					<path d="M4020 9035 c-7 -8 -18 -15 -25 -15 -7 0 -23 -8 -36 -19 -13 -10 -40
-24 -60 -31 -28 -10 -35 -17 -31 -32 5 -19 -12 -25 -23 -8 -18 29 -69 -5 -78
-52 -4 -21 -4 -20 -6 5 0 15 -7 27 -13 27 -20 -1 -49 -26 -40 -35 4 -5 2 -5
-5 -1 -7 4 -20 3 -30 -3 -10 -5 -30 -14 -45 -20 -16 -6 -28 -18 -28 -27 0 -14
-3 -14 -15 -4 -12 10 -19 10 -27 2 -7 -7 -22 -12 -34 -12 -17 0 -20 -5 -17
-25 3 -13 11 -30 19 -36 8 -6 14 -19 14 -29 0 -10 7 -21 16 -24 11 -4 14 -13
11 -27 -3 -11 1 -35 8 -52 l14 -32 1 33 c0 18 4 31 9 28 5 -3 7 -15 4 -26 -4
-16 -2 -19 12 -14 12 5 15 13 10 33 -18 72 -17 103 4 132 12 16 21 35 19 42
-4 17 4 21 30 13 14 -5 29 -3 38 5 12 9 20 9 39 -1 14 -7 25 -19 25 -25 0 -15
62 -158 76 -176 6 -8 14 -26 18 -41 7 -30 23 -37 32 -13 7 17 24 20 24 4 0 -6
-7 -17 -15 -25 -17 -17 -20 -44 -5 -44 6 0 10 5 10 11 0 5 4 8 9 4 6 -3 10
-22 9 -43 0 -44 24 -107 54 -139 14 -15 17 -23 8 -23 -9 0 -11 -11 -8 -38 5
-34 2 -42 -34 -76 -22 -21 -36 -42 -31 -47 14 -14 51 2 57 24 5 17 8 19 15 8
10 -15 35 -5 26 10 -4 5 -1 10 4 10 64 8 188 57 196 77 9 24 -12 39 -34 25
-14 -9 -24 -7 -47 7 -38 26 -64 60 -64 87 0 12 -4 24 -9 28 -5 3 -17 27 -26
55 -9 27 -24 59 -34 72 -10 13 -21 37 -24 53 -10 53 -32 105 -42 101 -5 -2
-11 11 -12 28 -2 17 -9 36 -18 42 -8 6 -12 13 -9 17 3 3 0 23 -6 45 -16 50 -3
76 42 87 66 17 88 12 119 -23 16 -18 29 -37 29 -43 0 -6 9 -8 21 -4 18 6 21 4
17 -13 -5 -18 -3 -19 18 -10 13 6 24 17 24 23 0 16 -33 45 -51 45 -8 0 -21 5
-29 11 -11 8 -7 9 18 5 17 -3 32 -3 32 0 0 4 -15 33 -32 65 -34 62 -62 76 -88
44z m45 -15 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4
4 -10z m-35 -15 c0 -2 -10 -9 -22 -15 -22 -11 -22 -10 -4 4 21 17 26 19 26 11z
m-3 -41 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-227 -119 c0
-8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m85
-35 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z
m-25 -75 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10
-15z m150 -239 c-6 -6 -10 -17 -10 -26 0 -19 -37 -44 -45 -30 -8 14 3 30 21
30 8 0 13 3 12 8 -1 4 2 18 6 31 6 18 10 20 17 10 5 -8 4 -18 -1 -23z m34 -61
c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m33
-187 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
					<path d="M3211 8623 c-6 -10 -11 -14 -11 -10 0 4 -15 -3 -33 -17 -19 -15 -39
-26 -46 -26 -7 0 -9 -4 -6 -10 3 -5 -2 -10 -13 -10 -25 0 -76 -55 -69 -74 10
-24 53 -4 50 23 -2 15 3 21 16 21 21 0 24 -30 3 -43 -9 -6 -10 -13 0 -30 11
-21 11 -21 20 -2 5 14 6 10 2 -12 -3 -19 -1 -33 4 -33 5 0 17 -16 26 -35 9
-19 21 -35 26 -35 6 0 10 -7 10 -15 0 -8 9 -15 21 -15 16 0 19 -4 14 -21 -5
-13 2 -33 19 -59 14 -22 26 -44 26 -49 0 -5 7 -11 15 -14 8 -4 24 -25 36 -49
12 -24 24 -37 26 -30 3 7 18 -14 34 -45 30 -59 36 -115 15 -142 -8 -11 -8 -15
2 -19 10 -3 9 -8 -4 -23 -27 -30 -4 -22 53 17 50 34 66 54 43 54 -5 0 -10 7
-10 15 0 8 5 15 10 15 6 0 13 -7 16 -15 4 -8 13 -15 20 -15 8 0 17 7 20 15 4
8 14 15 24 15 9 0 25 9 35 20 10 11 24 20 31 20 7 0 10 8 7 20 -3 11 -9 17
-14 14 -5 -3 -7 -10 -4 -15 3 -5 0 -6 -8 -3 -7 2 -25 0 -40 -5 -32 -12 -58 8
-105 83 -30 47 -31 48 -38 22 -3 -14 -10 -26 -15 -26 -12 0 -11 2 5 41 13 30
12 35 -8 63 -11 17 -26 36 -33 43 -6 7 -15 22 -18 33 -3 11 -15 20 -25 20 -10
0 -22 5 -25 10 -3 6 1 10 9 10 20 0 20 14 2 33 -8 8 -23 30 -33 49 -10 19 -30
58 -46 87 -20 38 -27 62 -25 96 2 27 8 45 15 45 7 0 10 7 7 15 -8 20 -21 19
-33 -2z m88 -358 c6 -3 11 -11 11 -17 0 -6 -9 -1 -20 12 -11 13 -20 30 -19 39
0 11 3 9 9 -5 5 -12 13 -25 19 -29z m47 -22 c-10 -10 -19 5 -10 18 6 11 8 11
12 0 2 -7 1 -15 -2 -18z m-76 8 c0 -5 3 -18 7 -28 6 -17 6 -17 -10 -1 -19 18
-22 38 -7 38 6 0 10 -4 10 -9z m134 -87 c9 -22 -3 -32 -14 -14 -12 19 -12 30
-1 30 5 0 12 -7 15 -16z m-74 -14 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6
5 10 10 10 6 0 10 -4 10 -10z m110 -256 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16
9 14 15 11 15 -7z"/>
					<path d="M9090 8478 c-48 -24 -102 -75 -131 -123 -43 -70 -51 -100 -52 -183 0
-76 9 -115 20 -84 3 6 9 10 14 6 5 -3 5 -13 -2 -25 -11 -22 5 -54 22 -43 5 3
9 -5 9 -16 0 -13 8 -24 20 -27 10 -3 26 -15 34 -28 15 -23 64 -53 101 -61 71
-16 108 -16 119 -2 16 21 46 31 39 12 -7 -19 21 -18 52 2 14 9 25 13 25 9 0
-12 83 67 105 100 50 73 61 120 57 236 -2 38 -6 48 -23 52 -12 3 -18 10 -15
16 16 26 -74 125 -105 116 -12 -4 -19 -2 -19 7 0 7 -22 23 -49 36 -50 23 -107
30 -96 12 3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 11 19 -59 10 -105 -12z m10
-48 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m-110 -100 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4
15 -10z m420 -241 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3
10 -10 10 -16z m-30 -129 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m-260 -25 c0 -8 -7 -15 -15 -15 -9 0 -12 6 -9 15 4 8 10
15 15 15 5 0 9 -7 9 -15z"/>
					<path d="M2990 8470 c0 -11 4 -20 9 -20 5 0 7 9 4 20 -3 11 -7 20 -9 20 -2 0
-4 -9 -4 -20z"/>
					<path d="M2681 8282 c-5 -10 -7 -30 -4 -43 6 -21 5 -22 -10 -11 -14 12 -23 9
-64 -25 -26 -21 -52 -44 -56 -50 -4 -7 -15 -13 -22 -13 -18 0 -19 -24 -2 -38
8 -7 18 -7 27 -2 27 17 57 11 75 -16 15 -23 17 -47 13 -185 -3 -123 -1 -159 9
-159 7 0 10 6 7 13 -2 7 0 31 5 52 9 39 9 39 12 10 1 -16 3 -43 3 -60 1 -38
41 -60 107 -59 31 0 46 -3 43 -11 -3 -7 22 -18 63 -29 37 -9 84 -21 103 -26
105 -28 184 3 192 75 l4 36 -40 -3 c-79 -4 -145 2 -158 15 -7 7 -47 19 -88 27
-41 7 -79 16 -85 20 -5 4 -28 11 -50 15 -21 3 -44 13 -50 21 -14 19 -11 350 4
387 14 36 14 50 0 42 -6 -4 -9 0 -7 10 4 25 -19 30 -31 7z m-41 -98 c0 -8 -5
-12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m-70 -54 c0 -5 -5 -10 -11 -10
-5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m117 -47 c-3 -10 -5 -2 -5 17
0 19 2 27 5 18 2 -10 2 -26 0 -35z m-27 0 c0 -21 -3 -24 -9 -14 -5 8 -7 20 -4
28 8 21 13 15 13 -14z m27 -70 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2
-26 0 -35z m63 -223 c8 -5 11 -10 5 -10 -5 0 -17 5 -25 10 -8 5 -10 10 -5 10
6 0 17 -5 25 -10z m33 -40 c-13 -5 -23 -14 -23 -20 0 -5 -9 -10 -20 -10 -11 0
-20 5 -20 10 0 6 9 10 20 10 11 0 20 5 20 10 0 6 10 10 23 9 l22 0 -22 -9z
m104 -32 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m206 5 c-7 -2
-19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-293 -13 c0 -5 -2 -10 -4 -10
-3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m233 -7 c-7 -2 -19 -2
-25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-96 -19 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m163 -8 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0
16 -2 16 -4z"/>
					<path d="M5390 8199 c-203 -12 -488 -55 -640 -96 -30 -8 -66 -17 -80 -20 -14
-2 -34 -8 -45 -12 -11 -5 -78 -27 -148 -50 -205 -67 -466 -185 -649 -293 -437
-258 -883 -682 -1143 -1088 -68 -106 -135 -220 -135 -230 0 -5 -4 -10 -8 -12
-10 -4 -121 -229 -157 -318 -14 -36 -30 -74 -35 -85 -33 -73 -81 -214 -112
-330 -6 -22 -17 -60 -25 -85 -21 -66 -58 -248 -74 -365 -12 -82 -26 -258 -35
-438 -22 -415 83 -1001 253 -1407 19 -47 42 -101 50 -120 49 -116 172 -346
249 -465 37 -58 218 -308 239 -331 5 -7 33 -39 60 -72 59 -71 296 -307 377
-375 210 -177 508 -371 723 -472 44 -20 107 -50 140 -65 226 -106 623 -216
900 -251 297 -37 579 -40 815 -9 41 6 92 10 112 10 21 0 57 4 80 10 24 5 70
15 103 21 88 18 352 84 410 104 103 34 334 130 453 187 118 57 398 223 490
291 414 304 754 681 1000 1108 62 108 147 277 171 339 8 19 24 58 37 86 37 82
142 404 165 505 36 161 55 274 74 439 19 165 19 571 0 750 -26 238 -55 397
-106 585 -13 47 -68 221 -79 250 -4 11 -14 38 -23 60 -19 53 -84 208 -111 267
-11 26 -25 48 -31 48 -5 0 -29 -35 -51 -78 -23 -42 -58 -106 -78 -140 -20 -35
-36 -71 -36 -81 0 -9 -6 -25 -13 -36 -8 -11 -34 -58 -59 -105 -25 -47 -69
-128 -98 -180 -80 -145 -106 -191 -130 -235 -12 -22 -31 -58 -44 -80 -32 -58
-85 -155 -146 -270 -29 -55 -61 -111 -70 -125 -10 -14 -34 -56 -54 -95 -20
-38 -40 -77 -45 -85 -4 -8 -14 -26 -21 -40 -50 -94 -78 -144 -115 -205 -51
-83 -119 -204 -188 -335 -27 -52 -53 -97 -56 -100 -3 -3 -15 -23 -27 -45 -11
-22 -54 -101 -96 -175 -41 -74 -95 -171 -118 -215 -24 -44 -46 -84 -51 -90 -4
-5 -26 -46 -49 -90 -24 -44 -67 -122 -96 -173 -30 -52 -54 -96 -54 -99 0 -4
-98 -181 -136 -243 -8 -14 -28 -50 -44 -80 -82 -156 -73 -145 -127 -144 -26 0
-82 7 -123 16 -41 9 -113 23 -160 32 -47 8 -98 17 -115 21 -57 10 -231 43
-347 66 l-116 22 -15 39 c-10 25 -22 38 -34 38 -30 0 -43 21 -43 70 0 25 -4
90 -10 145 -5 55 -14 165 -20 245 -6 80 -15 201 -21 270 -5 69 -19 247 -30
395 -11 149 -24 326 -30 395 -11 145 -23 308 -39 540 -6 91 -15 176 -19 190
-9 26 9 61 -321 -615 -141 -290 -272 -559 -350 -720 -40 -82 -135 -277 -211
-432 -77 -156 -139 -287 -139 -292 0 -17 -41 -23 -84 -12 -22 5 -81 18 -131
26 -49 9 -103 20 -120 25 -16 4 -64 13 -105 20 -41 7 -109 19 -150 28 -41 8
-97 19 -125 23 -27 4 -81 13 -120 20 -38 6 -78 19 -87 28 -12 11 -21 44 -27
106 -6 50 -15 128 -21 175 -7 47 -15 117 -20 155 -5 39 -20 156 -35 260 -15
105 -30 222 -35 260 -30 243 -47 374 -55 435 -7 49 -39 288 -55 410 -5 41 -14
111 -20 155 -6 44 -15 116 -21 160 -22 179 -32 257 -58 460 -6 44 -17 132 -26
195 -16 111 -26 183 -45 340 -5 41 -14 111 -20 155 -6 44 -15 116 -20 160 -5
44 -13 103 -18 130 -18 111 -7 128 67 107 20 -6 81 -18 136 -27 55 -9 123 -21
150 -26 81 -16 148 -28 185 -34 19 -3 80 -14 135 -25 55 -11 133 -26 174 -34
40 -8 79 -20 85 -26 6 -6 14 -68 17 -138 12 -205 28 -435 34 -459 3 -13 10
-96 16 -185 26 -392 39 -581 58 -838 6 -74 15 -198 20 -275 20 -268 31 -411
41 -530 6 -66 11 -133 13 -150 2 -28 5 -25 34 35 62 128 173 362 173 366 0 4
37 82 174 367 42 87 76 160 76 162 0 2 41 87 90 190 50 103 90 188 90 190 0 1
45 99 101 216 56 118 114 241 129 274 133 286 194 410 215 435 7 8 21 31 30
50 10 19 22 38 27 41 5 3 35 0 66 -6 31 -7 91 -18 132 -24 41 -7 91 -17 110
-22 19 -5 73 -16 120 -24 192 -32 428 -80 435 -87 4 -4 17 -158 35 -423 6 -88
15 -207 20 -265 6 -58 10 -135 10 -171 0 -36 4 -90 9 -120 5 -30 12 -103 15
-164 3 -60 8 -139 11 -175 3 -36 12 -157 20 -270 8 -113 18 -230 21 -260 5
-55 18 -239 30 -442 4 -60 11 -108 15 -108 8 0 26 35 109 215 15 33 52 112 83
175 30 63 73 156 97 205 23 50 62 131 85 180 82 172 157 330 192 405 64 137
105 224 163 345 97 203 160 336 211 443 l49 104 57 -4 56 -3 30 50 c28 46 163
293 245 445 19 36 79 148 135 250 55 102 113 210 129 239 l30 55 -134 131
c-265 262 -521 446 -848 611 -192 96 -464 205 -615 245 -92 24 -118 31 -197
48 -143 32 -352 60 -533 71 -186 11 -190 11 -385 -1z"/>
					<path d="M2391 8021 c-7 -11 -18 -21 -26 -21 -8 0 -15 -7 -15 -15 0 -9 -8 -19
-17 -22 -25 -9 -63 -46 -63 -60 0 -7 -6 -13 -13 -13 -15 0 -57 -33 -57 -44 0
-4 10 -5 23 -2 l22 6 -22 -11 c-13 -7 -23 -16 -23 -21 0 -12 12 -9 34 7 35 27
61 18 115 -40 28 -31 51 -58 51 -60 0 -3 23 -27 50 -54 28 -27 50 -52 50 -56
0 -4 14 -23 30 -42 17 -18 30 -37 30 -42 0 -4 11 -13 25 -19 14 -6 25 -18 25
-26 0 -8 6 -19 13 -23 47 -29 93 -145 61 -157 -17 -7 -19 -49 -1 -44 6 3 17
14 22 25 6 11 15 18 21 16 6 -2 17 3 24 12 10 12 10 18 0 30 -8 9 -8 15 -2 15
6 0 12 -6 15 -12 4 -15 122 77 122 96 0 6 -12 12 -27 14 -16 2 -28 -1 -28 -7
0 -23 -23 -9 -71 41 -27 28 -52 48 -57 43 -4 -4 -1 -10 6 -12 6 -3 12 -8 12
-13 0 -4 -11 -3 -25 3 -14 7 -23 17 -22 22 2 6 -3 16 -12 23 -14 11 -13 12 7
7 12 -4 22 -3 22 1 0 20 -56 85 -77 90 -14 4 -22 12 -19 19 2 7 -5 21 -16 32
-12 10 -28 30 -37 44 -14 23 -104 130 -138 164 -14 15 -6 53 15 78 14 16 14
19 0 33 -15 15 -17 14 -27 -5z m-11 -71 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9
10 3 6 10 10 16 10 5 0 9 -4 9 -10z m10 -125 c6 -8 8 -20 4 -26 -5 -9 -11 -7
-21 6 -23 33 -9 51 17 20z m36 -42 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7
1 -15 -2 -18z m80 -26 c4 -12 6 -23 5 -24 -3 -6 -16 -2 -16 5 -1 4 -6 17 -13
29 -11 19 -10 22 2 17 9 -3 18 -16 22 -27z m-29 -53 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m53 -69 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10 15
-3 8 -1 15 4 15 6 0 10 -7 10 -15z m108 -17 c-4 -12 -54 -20 -71 -11 -17 9 6
23 39 23 24 0 34 -4 32 -12z m22 -133 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15
0 8 5 15 10 15 6 0 10 -7 10 -15z m40 -50 c0 -8 -4 -15 -10 -15 -5 0 -10 7
-10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m70 -5 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m65 -20 c-3 -5 -11 -10 -16
-10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z"/>
					<path d="M3200 7736 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
					<path d="M1928 7549 c-10 -11 -15 -26 -11 -35 4 -11 3 -15 -5 -10 -6 3 -17 -1
-24 -9 -7 -9 -21 -25 -30 -36 -10 -10 -18 -23 -18 -29 0 -5 -11 -17 -25 -26
-14 -9 -25 -23 -25 -30 0 -19 39 -18 46 1 4 8 14 15 23 15 14 -1 13 -3 -3 -15
-21 -16 -21 -17 11 -34 30 -15 51 -15 35 1 -17 17 -15 29 3 22 8 -4 15 -14 15
-25 0 -18 51 -73 129 -138 34 -28 47 -33 81 -29 28 2 40 0 40 -9 0 -9 -8 -11
-25 -7 -29 7 -33 -5 -7 -26 71 -60 85 -70 93 -70 6 0 8 10 4 23 -5 19 -4 20 5
4 6 -9 8 -22 5 -28 -4 -6 12 -26 34 -46 41 -35 42 -37 40 -94 -2 -60 14 -100
34 -80 7 7 6 15 -2 25 -7 8 -11 23 -8 33 3 15 6 14 14 -7 l11 -25 12 28 c7 15
17 27 22 27 5 0 6 10 3 23 -5 17 -4 19 4 7 10 -13 15 -8 12 13 0 4 5 6 11 5 8
-2 14 9 16 25 2 18 9 27 21 27 22 0 61 41 61 63 0 25 -29 21 -39 -5 -5 -13 -7
-26 -4 -30 2 -5 -1 -8 -6 -8 -6 0 -11 5 -11 10 0 6 -10 8 -24 4 -15 -3 -27 -1
-32 8 -6 9 -10 6 -15 -12 l-7 -25 -1 27 c-1 17 -9 32 -23 39 -13 7 -28 19 -35
28 -7 9 -13 12 -13 7 0 -11 -40 34 -40 46 0 4 -4 8 -10 8 -5 0 -18 8 -28 18
-40 38 -82 72 -90 72 -4 0 -13 9 -20 21 -7 11 -19 18 -27 15 -9 -3 -12 0 -8
10 4 10 -2 17 -16 21 -11 3 -21 12 -21 19 0 8 -9 14 -20 14 -11 0 -20 6 -20
14 0 7 -11 21 -25 30 -14 9 -23 21 -20 27 4 5 -2 12 -11 16 -10 4 -19 2 -22
-5 -2 -7 -7 -12 -12 -12 -5 0 -2 14 7 30 8 17 12 38 9 46 -5 12 -2 15 10 10
11 -4 15 -1 12 16 -4 28 -19 31 -40 7z m32 -175 c0 -9 -5 -14 -12 -12 -18 6
-21 28 -4 28 9 0 16 -7 16 -16z m20 -45 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10
16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m95 -59 c-3 -5 -10 -10 -16 -10 -5 0 -9
5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m79 -45 c8 -8 19 -15 25 -15 5 0 12
-4 15 -9 4 -5 -2 -7 -11 -5 -24 7 -71 43 -56 44 6 0 18 -7 27 -15z m-66 -20
c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m132 -91 c0 -2 -7 -4
-15 -4 -8 0 -15 4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15 -10z m70 -4 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m130 -121 c0
-5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m-60
-15 c0 -8 5 -14 10 -14 6 0 10 -8 10 -17 0 -15 -4 -13 -20 7 -21 26 -27 56
-10 45 6 -3 10 -13 10 -21z"/>
					<path d="M2880 7484 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z" />
					<path d="M1758 7348 c-3 -7 2 -19 10 -27 12 -12 15 -12 23 0 12 20 11 26 -7
33 -21 8 -22 8 -26 -6z"/>
					<path d="M1526 7064 c-10 -14 -20 -43 -23 -65 -3 -22 -10 -42 -14 -45 -5 -3
-6 3 -2 13 6 16 5 16 -11 4 -11 -10 -15 -22 -11 -38 4 -18 1 -23 -14 -23 -10
0 -22 -7 -25 -16 -11 -28 -6 -54 9 -54 8 0 15 9 15 19 0 11 13 27 28 37 16 9
34 32 41 50 11 32 31 47 31 24 0 -5 -4 -10 -9 -10 -5 0 -11 -9 -14 -21 -5 -17
2 -24 39 -41 32 -14 44 -24 40 -34 -3 -8 -2 -13 3 -9 5 3 17 2 26 -1 13 -5 14
-9 3 -15 -10 -7 -8 -13 11 -28 14 -10 31 -16 40 -13 8 3 12 1 8 -5 -3 -5 9
-24 28 -41 19 -16 35 -34 35 -40 0 -5 15 -23 33 -39 77 -73 147 -153 147 -168
0 -26 -29 -50 -43 -36 -6 6 -23 11 -38 11 -15 0 -44 7 -65 16 -21 9 -66 17
-99 17 -33 1 -50 3 -37 5 39 6 23 22 -22 22 -25 0 -47 4 -50 9 -8 12 -66 20
-66 9 0 -4 -5 -8 -10 -8 -6 0 -9 5 -8 11 2 5 -15 17 -37 24 -55 20 -68 19 -62
-5 3 -13 0 -20 -9 -20 -7 0 -18 9 -24 20 -9 16 -8 20 5 20 17 0 20 16 4 25 -6
4 -9 29 -8 56 4 71 -12 80 -45 25 -30 -51 -35 -76 -11 -56 10 9 15 9 15 1 0
-10 -12 -15 -36 -12 -6 1 -9 -12 -7 -31 2 -24 1 -28 -5 -14 -7 15 -13 7 -37
-45 -15 -35 -34 -70 -42 -77 -14 -15 -9 -32 10 -32 14 0 63 43 70 62 2 8 8 9
13 4 5 -5 4 -11 -3 -13 -7 -3 -13 -9 -13 -15 0 -7 17 -9 47 -5 47 5 54 2 97
-42 19 -19 30 -22 53 -17 27 7 27 7 8 -8 -19 -14 -17 -17 53 -85 45 -43 82
-71 95 -71 16 0 18 -3 9 -9 -10 -6 0 -24 42 -71 59 -66 64 -75 44 -83 -10 -4
-10 -6 0 -6 7 -1 10 -5 7 -11 -10 -16 -52 -12 -59 6 -4 12 -14 14 -36 9 -21
-5 -35 -2 -46 9 -8 9 -25 16 -37 16 -12 0 -41 7 -65 15 -24 9 -48 13 -53 10
-5 -4 -19 1 -31 9 -12 9 -32 16 -43 16 -11 0 -33 7 -49 15 -15 8 -41 15 -57
15 -15 0 -31 5 -34 10 -3 6 -12 10 -18 10 -7 0 -6 -4 3 -10 13 -9 13 -11 0
-20 -23 -15 -32 -12 -28 9 2 11 -7 30 -20 44 -22 24 -23 26 -7 63 13 33 14 40
1 52 -20 20 -37 7 -26 -22 5 -13 4 -29 -1 -37 -6 -10 -9 -10 -9 -1 0 22 -15
13 -32 -20 -77 -157 -82 -171 -63 -184 14 -11 21 -10 41 2 13 9 28 13 34 9 6
-4 25 -8 43 -11 29 -3 63 -10 172 -34 25 -5 41 -5 44 1 1 6 11 3 21 -6 10 -9
37 -18 60 -20 23 -2 47 -8 53 -13 24 -19 137 -33 155 -20 8 8 12 6 12 -6 0 -9
11 -18 28 -22 15 -3 40 -10 56 -15 37 -12 69 -12 61 0 -3 5 -1 12 5 16 5 3 10
17 10 30 0 13 5 27 10 30 12 7 13 41 3 48 -5 2 -3 2 4 1 7 -2 15 1 18 5 6 11
-102 107 -116 103 -6 -1 -8 2 -4 7 8 13 -16 51 -32 51 -7 0 -13 7 -13 16 0 12
-6 15 -20 11 -17 -4 -20 -2 -15 15 5 14 1 21 -14 25 -11 3 -18 9 -15 14 3 5 1
9 -4 9 -5 0 -20 16 -33 35 -24 35 -44 44 -51 23 -3 -8 -11 -9 -24 -3 -18 8
-18 9 4 12 12 3 22 10 22 17 0 16 61 27 103 19 20 -4 28 -3 23 5 -3 6 -2 13 4
17 5 3 10 1 10 -4 0 -6 7 -11 15 -11 8 0 15 -4 15 -10 0 -5 11 -12 25 -16 14
-3 25 -10 25 -15 0 -5 15 -9 33 -9 18 0 38 -5 43 -10 8 -8 19 -8 38 0 39 14
46 13 46 -10 0 -22 35 -29 43 -7 3 9 7 9 20 -2 23 -19 44 -8 52 28 4 17 10 31
15 31 11 0 18 56 8 74 -4 8 -33 40 -64 72 -50 51 -58 56 -69 41 -11 -16 -13
-16 -29 1 -23 26 -20 35 9 28 14 -4 25 -2 25 4 0 5 -6 10 -14 10 -7 0 -16 10
-19 21 -4 15 -11 20 -21 16 -9 -4 -16 -2 -16 3 0 9 -20 36 -49 67 -11 12 -15
11 -27 -4 -16 -22 -19 -10 -3 15 9 15 2 27 -44 76 -36 39 -56 54 -60 45 -3 -8
-8 1 -12 19 -4 17 -13 32 -20 32 -6 0 -18 11 -25 25 -7 13 -17 22 -22 19 -4
-3 -8 6 -8 19 0 35 -20 87 -33 87 -7 0 -20 -12 -31 -26z m43 -54 l-1 -25 -8
25 c-4 14 -8 32 -9 40 -1 13 0 13 9 0 5 -8 9 -26 9 -40z m111 -152 c0 -6 -6
-5 -15 2 -8 7 -15 14 -15 16 0 2 7 1 15 -2 8 -4 15 -11 15 -16z m50 -43 c7 -9
8 -15 2 -15 -5 0 -12 7 -16 15 -3 8 -4 15 -2 15 2 0 9 -7 16 -15z m100 -99 c0
-3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-493
-128 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m33 -40 c0 -5 -7
-8 -15 -8 -17 0 -18 2 -9 25 5 13 8 14 15 3 5 -7 9 -16 9 -20z m658 -58 c5
-13 3 -13 -11 -1 -17 13 -23 6 -41 -51 -3 -10 -12 -18 -21 -18 -19 0 -19 14 0
30 9 7 13 15 10 18 -9 9 28 43 44 40 8 -2 17 -10 19 -18z m-381 -13 c3 -4 -5
-7 -20 -7 -14 0 -28 4 -31 8 -5 9 41 8 51 -1z m113 3 c0 -5 -19 -10 -42 -9
-37 1 -39 2 -18 9 36 12 60 12 60 0z m261 -67 c-10 -17 -10 -16 -11 3 0 23 13
41 18 25 2 -6 -1 -18 -7 -28z m-166 -4 c10 -14 1 -21 -19 -13 -9 3 -13 10 -10
15 7 12 21 11 29 -2z m-163 -201 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3
-1 -11 -7 -17z m-625 -74 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m323 -4 c12 -8 12 -10 -3 -16 -21 -8 -67 3 -67 16 0 13 50 13 70 0z m-77
-47 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m109 -5 c-17 -8
-26 -8 -34 0 -9 9 -4 12 23 11 32 0 33 -1 11 -11z m150 0 c-9 -9 -15 -9 -24 0
-9 9 -7 12 12 12 19 0 21 -3 12 -12z m-215 -4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m163 -44 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z m280 -10 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20
0 11 5 20 10 20 6 0 10 -9 10 -20z m-33 -62 c-3 -7 -5 -2 -5 12 0 14 2 19 5
13 2 -7 2 -19 0 -25z"/>
					<path d="M985 6089 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z" />
					<path d="M1010 6060 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
					<path d="M0 35 l0 -35 5400 0 5400 0 0 35 0 35 -5400 0 -5400 0 0 -35z" />
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
